import React,{useEffect,useState} from 'react';
import { toast } from 'sonner';
import Loader from '../component/loader'
import Approve from "../../../assets/images/icons8-tick-48.png"
import Reject from "../../../assets/images/icons8-cross-48.png"


const headings = [
  { title: 'Accounts', id: '1' },
  { title: 'Email', id: '2' },
  { title: 'Actions', id: '3' },
]

const ApproveUsers = () => {
  const [users,setUsers]=useState();
  const [loader,setLoader]=useState(true);
  const [currentPage,setCurrentPage]=useState(1);
  const [usersPerPage] =useState(10);
  const indexOfLastUser =usersPerPage*currentPage;
  const indexOfFirstUser = indexOfLastUser-usersPerPage;
  const currentUsers = users?.slice(indexOfFirstUser,indexOfLastUser);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users?.length / usersPerPage); i++) {
      pageNumbers.push(i);
  }
  const handlePageChange=(pageNumber)=>{
    setCurrentPage(pageNumber)
}
  async function approveUser(userId,email,name){
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/approve-user/${userId}/${email}/${name}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            console.log("user approve response",response)
            if(response.code === 200)
       {
        const updatedUsers = users.filter((user)=>{
            return userId !== user._id
          });
          setUsers(updatedUsers)
            toast.info("User Approved Successfully!");
       }
            
          }
        })
        .catch((err)=>{
          console.log("Error approving users",err)
        })
    } catch (error) {
      console.log("Error approving users",error)
    }
  }
  async function rejectUser(userId,email,name){
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/reject-user/${email}/${name}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            const updatedUsers = users.filter((user)=>{
              return userId !== user._id
            });
            setUsers(updatedUsers)
            toast.info("User Rejected Successfully!");
            
          }
        })
        .catch((err)=>{
          console.log("Error Rejected users",err)
        })
    } catch (error) {
      console.log("Error Rejected users",error)
    }
  }
  useEffect(()=>{
    try {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/fetch-unapproved-users`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('fetch users response', res);
          if (res.ok) {
            const response = await res.json();
            console.log("first",response)
            setUsers(response.users)
            setLoader(false)
           
          }
        })
        .catch((err)=>{
          console.log("Error fetching users",err)
        })
    } catch (error) {
      console.log("Error fetching users",error)
    }
    },[])
   
   
  
  
    
  return (
    <div className="px-10 h-full">
      <div className="sm:flex sm:items-center lg:mt-20">
        <div className="flex  w-full">
          <h1 className="flex justify-center lg:justify-start text-4xl font-semibold leading-6 text-gray-900 w-full tex-left">Approve Users</h1>
            
          
      

        </div>
      </div>
     
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className='border-gray-200 border rounded-t-sm'>
                  {headings.map((heading) => (
                    <th key={heading.id} scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      <a href="#" className="group inline-flex">
                        {heading.title}
                      </a>
                    </th>
                  ))}
                  
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {currentUsers?.map((person,index) => (
                  <tr key={person._id} className='border border-gray-200'>
                    <td className="whitespace-nowrap text-center py-4 px-3 text-sm font-medium text-gray-900">
                      {person.fullname}
                    </td>
                    <td className="whitespace-nowrap text-center py-4 px-3 text-sm font-medium text-gray-900">
                      {person.email}
                    </td>
                    <td className="whitespace-nowrap text-center py-4 px-3 text-sm font-medium text-gray-900">
                        <button title='Approve'  onClick={()=>{
                            approveUser(person._id,person.email,person.fullname)
                        }}>
                            <img  src={Approve} className="w-10 h-10 pb-0.5"></img>
                        </button>
                        <button title='Reject'  onClick={()=>{
                            rejectUser(person._id,person.email,person.fullname)
                        }}>
                            <img className="w-12 h-12 pt-1" src={Reject} ></img>
                        </button>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
                
            </div>
        </div>
        <div className="flex justify-center items-end mt-10">
{pageNumbers.map(number => (
    <button
        key={number}
        onClick={() => handlePageChange(number)}
        className={`mx-1 px-4 py-2 border rounded-lg text-sm transition-colors duration-300 ${currentPage === number ? 'bg-teal-500 text-white border-teal-500' : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200'}`}
    >
        {number}
    </button>
))}
</div>
      </div>
      {loader && (
  <div className='flex justify-center items-center h-full'>
    <Loader/>
  </div>
)}
    </div>
  )
}
export default ApproveUsers